<template>
  <div class="main-view">
    <div class="section filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="small">
        <el-form-item prop="goods_name">
          <el-input v-model.trim="table.params.goods_name" placeholder="产品名称">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="type">
          <el-select v-model="table.params.type" placeholder="综合评分" @change="onSearch">
            <el-option label="1-2分" :value="1"></el-option>
            <el-option label="2-3分" :value="2"></el-option>
            <el-option label="3-4分" :value="3"></el-option>
            <el-option label="4-5分" :value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:title="{row}">
        <div class="flex" style="align-items:center;">
          <el-image
            style="width: 80px;border-radius:2px;margin-right:10px"
            :src="row.good_info.image"
            fit="cover"
            :preview-src-list="[row.good_info.image]"></el-image>
            <div style="width:200px">
              <div class="table-col-text">{{row.title}}</div>
              <div class="table-col-text">订单编号：{{row.order_no}}</div>
            </div>
        </div>
      </template>
      <template v-slot:score="{row}">
        <el-rate v-model="row.score" disabled></el-rate>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click='showDetail(row)'>查看评价</el-button>
      </template>
    </VTable>


    <detail ref="detail"></detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'ProductScore',
  components: {
    VTable,
    Detail,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "title", label: "研学主题", width:'300', hidden: false },
        { name: "company_name", label: "所属机构", showTooltip:'true', hidden: false },
        { name: "good_title", label: "产品名称", showTooltip:'true', hidden: false },
        { name: "teacher_name", label: "评价人", hidden: false},
        { name: "school_name", label: "学校", hidden: false},
        { name: "create_time", label: "评价时间", hidden: false},
        { name: "score", label: "评分", hidden: false},
        { name: "action", label: "操作", width: "110", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          title: '',
          goods_name: '',
          company_name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/government/appraise/mien', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 评价详情
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    
  }
}
</script>

<style scoped lang="scss">
.my-drawer {
  &::v-deep {
    .el-overlay {
      position: absolute;
    }
    .el-drawer__header {
      margin: 0;
      padding: 10px 20px;
    }
    
  }
}
.el-dialog-title {
  font-weight: bold;
}
.myTooltipsDrawer {
  max-width: 700px;
}
</style>